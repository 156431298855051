/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
// trigger an immediate page refresh when an update is found
import "./styles.css"

require("prismjs/themes/prism.css")

export const onServiceWorkerUpdateReady = () => window.location.reload()
